<template>
  <div>
    <EmployeeAttendancePageTableControl class="mb-5" />
    <AppPageCard>
      <template #card-title>
        {{ $t("ATTENDANCE.HEADER") }}
      </template>

      <template #card-toolbar>
        <MainEmployeeAttendancePageControl></MainEmployeeAttendancePageControl>
      </template>
      <b-nav pills>
        <b-nav-item to="daily" exact exact-active-class="active"
          >Daily</b-nav-item
        >
        <b-nav-item to="monthly" exact exact-active-class="active"
          >Monthly</b-nav-item
        >
        <b-nav-item to="yearly" exact exact-active-class="active"
          >Yearly</b-nav-item
        >
      </b-nav>
      <div class="mt-5">
        <router-view> </router-view>
      </div>
    </AppPageCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { alert } from "sweetalert2";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import MainEmployeeAttendancePageControl from "@/modules/company/components/attendance/MainEmployeeAttendancePageControl";
import EmployeeAttendancePageTableControl from "@/modules/company/components/attendance/control/EmployeeAttendancePageTableControl";

export default {
  name: "EmployeeAttendancePage",
  components: {
    EmployeeAttendancePageTableControl,
    MainEmployeeAttendancePageControl,
    AppPageCard,
  },
  data: function () {
    return {
      reload: false,
      tabIndex: 0,
      isBusy: {
        report: false,
      },
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("SIDEBAR.COMPANY") },
      { title: this.$t("SIDEBAR.EMPLOYEE"), route: "/company/employee/list" },
      {
        title: this.$t("SIDEBAR.ATTENDANCE"),
        route: "/company/employee/attendance",
      },
    ]);
  },
  methods: {
    refresh() {
      this.reload = !this.reload;
    },
    download() {
      console.log("Downloading Attendance Report.");

      switch (this.tabIndex) {
        case 0:
          this.downloadDaily();
          break;
        case 1:
          this.downloadMonthly();
          break;
        default:
          break;
      }
    },

    downloadMonthly() {
      this.isBusy.report = true;
      console.log("Downloading, isBusy?", this.isBusy.report);
      this.$store
        .dispatch("downloadMonthlyReport")
        .then()
        .catch(() => {
          alert({
            title: "Failed",
          });
        })
        .finally(() => (this.isBusy.report = false));
    },

    downloadDaily() {
      this.isBusy.report = true;
      console.log("Downloading, isBusy?", this.isBusy.report);
      this.$store
        .dispatch("downloadDailyReport")
        .then()
        .catch(() => {
          alert({
            title: "Failed",
          });
        })
        .finally(() => (this.isBusy.report = false));
    },
  },
};
</script>

<style scoped></style>

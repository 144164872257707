<template>
  <AppTableControl
    no-filter
    @query-debounced="searchByName"
    @export-excel-clicked="downloadDailyReport(1)"
    @export-pdf-clicked="downloadDailyReport(2)"
  ></AppTableControl>
</template>

<script>
import AppTableControl from "@/modules/core/components/shared/table/AppTableControl";

export default {
  name: "EmployeeDailyAttendanceTableControl",
  components: { AppTableControl },

  beforeDestroy() {
    console.log("Resetting name base (all) query filter");

    this.$store.dispatch("changeEmployeeDailyAttendanceBaseFilter", {
      base: null,
    });
  },

  methods: {
    /**
     * Search using name query.
     *
     * @param nameQuery
     */
    searchByName(nameQuery) {
      console.log(nameQuery);

      this.$store.dispatch("changeEmployeeDailyAttendanceBaseFilter", {
        base: nameQuery,
      });

      this.$store.dispatch("fetchEmployeeDailyAttendance");
    },

    /**
     * Asyncly download report.
     *
     * @param type
     */
    async downloadDailyReport(type) {
      try {
        this.$bvToast.toast("Downloading Report . . .", {
          variant: "primary",
        });

        await this.$store.dispatch("downloadEmployeeDailyReport", { type });

        this.$bvToast.toast("Successfully downloaded report . . .", {
          variant: "primary",
        });
      } catch (e) {
        console.error(e);

        await this.$bvModal.msgBoxOk("Error", {
          okVariant: "danger",
        });
      }
    },
  },
};
</script>

<style scoped></style>

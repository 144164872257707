<template>
  <AppTableControl
    @search-clicked="beginSearch"
    @query-debounced="searchByName"
    @export-excel-clicked="downloadYearlyReport(1)"
    @export-pdf-clicked="downloadYearlyReport(2)"
  >
    <template #filters>
      <AppFieldYearSelector
        :date-value="engageDate"
        no-search
        @clickedSearch="fetchFilteredDate"
        @fetched-date="fetchFilteredDate"
        @date-selected="changeDate"
      ></AppFieldYearSelector>
    </template>
  </AppTableControl>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import AppTableControl from "@/modules/core/components/shared/table/AppTableControl";
import AppFieldYearSelector from "@/modules/core/components/utility/date/AppFieldYearSelector";

export default {
  name: "EmployeeYearlyAttendanceTableControl",
  components: { AppFieldYearSelector, AppTableControl },
  data() {
    return {
      engageDate: false,
    };
  },

  beforeDestroy() {
    console.log("Resetting filters");

    // Year date filter
    this.$store.dispatch("changeEmployeeYearlyAttendanceListDate", {
      date: dayjs().format(),
    });

    // Search base name filter
    this.$store.dispatch("changeEmployeeYearlyAttendanceBaseFilter", {
      name: null,
    });
  },

  methods: {
    /**
     * Fetch
     */
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchEmployeeYearlyAttendanceList")
        .then(() => {})
        .finally(() => (this.isBusy = false));
    },

    /**
     * Filter date.
     *
     * @param data
     */
    fetchFilteredDate(data) {
      console.log("Date ", data);
      this.changeDate(data);

      this.fetch();
    },

    /**
     * Search by name query.
     *
     * @param nameQuery
     */
    searchByName(nameQuery) {
      console.log(nameQuery);

      this.$store.dispatch("changeEmployeeYearlyAttendanceBaseFilter", {
        name: nameQuery,
      });

      this.fetch();
    },

    /**
     * Commit search.
     */
    beginSearch() {
      console.log("Begin search");

      this.engageDate = !this.engageDate;
    },

    /**
     * Change date.
     *
     * @param data
     */
    changeDate(data) {
      const objectSupport = require("dayjs/plugin/objectSupport");
      dayjs.extend(objectSupport);

      const date = dayjs({
        year: data.year,
      });

      this.year = data.year;

      this.$store.dispatch("changeEmployeeYearlyAttendanceListDate", {
        date: date.format(),
      });
    },

    /**
     * Download report by type
     *
     * @param type
     */
    async downloadYearlyReport(type) {
      // Date changed via changeDate method
      console.log("Selected type is : ", type);

      try {
        this.$bvToast.toast("Downloading Report . . .", {
          variant: "primary",
        });

        const response = await this.$store.dispatch(
          "downloadEmployeeYearlyAttendanceReport",
          { type }
        );

        console.log(response);
        console.log("Successfully downloaded report of type : ", type);

        this.$bvToast.toast("Successfully downloaded report . . .", {
          variant: "primary",
        });
      } catch (e) {
        console.error(e);

        await this.$bvModal.msgBoxOk("Error", {
          okVariant: "danger",
        });
      }
    },
  },

  computed: {
    ...mapGetters({
      dateSelected: "getEmployeeYearlyAttendanceListDate",
    }),
  },
};
</script>

<style scoped></style>

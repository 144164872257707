<template>
  <div>
    <!--    <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>-->
    <!--      <template #button-content>-->
    <!--        <b-button-->
    <!--          variant="primary"-->
    <!--          class="btn btn-icon btn-light btn-hover-primary mr-3"-->
    <!--          v-bind:class="{-->
    <!--            'spinner spinner-right spinner-light ml-10': isBusy,-->
    <!--          }"-->
    <!--        >-->
    <!--          <span class="svg-icon svg-icon-2x svg-icon-primary">-->
    <!--            <inline-svg src="/media/svg/icons/Communication/Group.svg" />-->
    <!--          </span>-->
    <!--        </b-button>-->
    <!--      </template>-->
    <!--      <b-dropdown-item @click="$emit('downloadClicked', { format: 1 })"-->
    <!--        >Excel</b-dropdown-item-->
    <!--      >-->
    <!--      <b-dropdown-item @click="$emit('downloadClicked', { format: 2 })"-->
    <!--        >PDF</b-dropdown-item-->
    <!--      >-->
    <!--    </b-dropdown>-->
    <b-button
      variant="primary"
      class="btn btn-icon btn-light btn-hover-primary mr-3"
      v-on:click="$emit('refreshClicked', $event)"
      v-b-tooltip.hover="$t('EMPLOYEE.HOVER.REFRESH')"
    >
      <span class="svg-icon svg-icon-2x svg-icon-primary">
        <inline-svg src="/media/svg/icons/Media/Repeat.svg" />
      </span>
    </b-button>
  </div>
</template>

<script>
export default {
  name: "EmployeeAttendancePageControl",
  props: {
    isBusy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
};
</script>

<style scoped></style>

<template>
  <EmployeeDailyAttendanceTableControl v-if="type === 1" />
  <EmployeeMonthlyAttendanceTableControl v-else-if="type === 2" />
  <EmployeeYearlyAttendanceTableControl v-else-if="type === 3" />
</template>

<script>
import EmployeeDailyAttendanceTableControl from "@/modules/company/components/attendance/control/EmployeeDailyAttendanceTableControl";
import EmployeeMonthlyAttendanceTableControl from "@/modules/company/components/attendance/control/EmployeeMonthlyAttendanceTableControl";
import EmployeeYearlyAttendanceTableControl from "@/modules/company/components/attendance/control/EmployeeYearlyAttendanceTableControl";
export default {
  name: "EmployeeAttendancePageTableControl",
  components: {
    EmployeeYearlyAttendanceTableControl,
    EmployeeMonthlyAttendanceTableControl,
    EmployeeDailyAttendanceTableControl,
  },
  data() {
    return {
      type: null,
    };
  },
  methods: {
    checkRoute(name) {
      switch (name) {
        case "employeeAttendance":
          this.type = 1;
          break;
        case "employeeAttendanceMonthly":
          this.type = 2;
          break;
        case "employeeAttendanceYearly":
          this.type = 3;
          break;
        default:
          this.type = 4;
          break;
      }
    },
  },
  mounted() {
    this.checkRoute(this.$route.name);
  },
  watch: {
    $route(to, from) {
      console.log(to, from);

      this.checkRoute(to.name);
    },
  },
};
</script>

<style scoped></style>

<template>
  <AppTableControl
    @search-clicked="beginSearch"
    @query-debounced="searchByName"
    @export-excel-clicked="downloadReport(1)"
    @export-pdf-clicked="downloadReport(2)"
  >
    <template #filters>
      <div class="d-flex">
        <b-form-group label="Type">
          <b-form-select
            v-model="selectedType"
            :options="monthlySelections"
            @input="
              $store.dispatch('changeEmployeeMonthlyAttendanceDateType', {
                type: $event,
              })
            "
          ></b-form-select>
        </b-form-group>

        <!-- begin:: specific date -->
        <b-form-group v-if="selectedType === 1">
          <AppFieldDateSelector
            :date-value="engageDate"
            no-search
            @clickedSearch="filterDate"
            @fetched-date="filterDate"
            @select-date="formatSpecificDate"
          >
          </AppFieldDateSelector>
        </b-form-group>
        <!-- end:: specific date -->

        <!-- begin:: date range -->
        <b-form-group class="ml-4" label="From Date" v-if="selectedType === 2">
          <DatePicker v-model="dateRange[0]" />
        </b-form-group>

        <b-form-group class="ml-4" label="To Date" v-if="selectedType === 2">
          <DatePicker v-model="dateRange[1]" />
        </b-form-group>
        <!-- end:: date range -->
      </div>
    </template>
  </AppTableControl>
</template>

<script>
import dayjs from "dayjs";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import AppTableControl from "@/modules/core/components/shared/table/AppTableControl";
import AppFieldDateSelector from "@/modules/core/components/utility/date/AppFieldDateSelector";
import objectSupport from "dayjs/plugin/objectSupport";

export default {
  name: "EmployeeMonthlyAttendanceTableControl",
  components: { AppFieldDateSelector, AppTableControl, DatePicker },
  data() {
    return {
      type: 1,
      selectedType: 1,
      monthlySelections: [
        { value: 1, text: "Specific" },
        { value: 2, text: "Range" },
      ],
      dateRange: [dayjs().toDate(), dayjs().toDate()],
      specificDate: dayjs(),
      engageDate: false,
    };
  },

  beforeDestroy() {
    // Reset filters
    console.log("Resetting Monthly Filters");

    // Date month filter
    this.$store.dispatch("defineEmployeeMonthlyAttendanceDateFilter", {
      date: dayjs().format(),
    });

    // From and To Date filters
    this.$store.dispatch("changeEmployeeMonthlyAttendanceRangeFilter", {
      from_date: dayjs().toDate(),
      to_date: dayjs().toDate(),
    });

    // Base (name) filter
    this.$store.dispatch("changeEmployeeMonthlyAttendanceBaseFilter", {
      name: null,
    });
  },

  methods: {
    /**
     * Search name query
     *
     * @param nameQuery
     */
    searchByName(nameQuery) {
      console.log(nameQuery);

      this.$store.dispatch("changeEmployeeMonthlyAttendanceBaseFilter", {
        name: nameQuery,
      });

      if (this.selectedType === 1)
        this.$store.dispatch("fetchEmployeeMonthlyAttendanceList");
      else if (this.selectedType === 2)
        this.$store.dispatch("fetchEmployeeMonthlyRangeAttendanceList");
    },

    /**
     * Format date
     * @param date
     */
    formatSpecificDate(date) {
      console.log("Formatting specific date");
      dayjs.extend(objectSupport);

      this.specificDate = dayjs({
        year: date.year,
        month: date.month,
      });
    },

    /**
     * Filter specific date
     *
     * @param data
     */
    filterDate(data) {
      console.log("Date ", data);
      const objectSupport = require("dayjs/plugin/objectSupport");
      dayjs.extend(objectSupport);

      const date = dayjs({
        year: data.year,
        month: data.month,
      });

      // Change type then change the specific date filter for individual attendance
      this.$store.dispatch("changeIndividualEmployeeAttendanceDateType", {
        type: 1,
      });
      this.$store.dispatch("changeIndividualEmployeeAttendanceSpecificDate", {
        date: this.specificDate,
      });

      this.$store.dispatch("defineEmployeeMonthlyAttendanceDateFilter", {
        date: date.format(),
      });

      this.$store.dispatch("fetchEmployeeMonthlyAttendanceList");
    },

    /**
     * Search click event
     */
    beginSearch() {
      console.log("Begin search");

      if (this.selectedType === 1) this.engageDate = !this.engageDate;
      else if (this.selectedType === 2) {
        this.changeRange();
        this.$store.dispatch("fetchEmployeeMonthlyRangeAttendanceList");
      }
    },

    /**
     * Change range type
     */
    changeRange() {
      console.log("Date range type search.");

      const queries = {
        from_date: this.dateRange[0],
        to_date: this.dateRange[1],
      };

      this.$store.dispatch(
        "changeEmployeeMonthlyAttendanceRangeFilter",
        queries
      );

      // Change type then change the specific date filter for individual attendance
      this.$store.dispatch("changeIndividualEmployeeAttendanceDateType", {
        type: 2,
      });
      this.$store.dispatch(
        "changeIndividualEmployeeAttendanceDateRange",
        queries
      );
    },

    /**
     * Download Report
     *
     * @param type
     */
    async downloadReport(type) {
      console.log(this.specificDate.format());

      if (this.selectedType === 1)
        await this.$store.dispatch(
          "defineEmployeeMonthlyAttendanceDateFilter",
          {
            date: this.specificDate.format(),
          }
        );
      else this.changeRange();

      try {
        this.$bvToast.toast("Downloading Report . . .", {
          variant: "primary",
        });

        await this.$store.dispatch("downloadMonthlyReport", {
          type,
        });

        this.$bvToast.toast("Successfully downloaded report . . .", {
          variant: "primary",
        });
      } catch (e) {
        console.error(e);

        await this.$bvModal.msgBoxOk("Error", {
          okVariant: "danger",
        });
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <EmployeeAttendancePageControl
    @refreshClicked="refresh"
  ></EmployeeAttendancePageControl>
</template>

<script>
import EmployeeAttendancePageControl from "@/modules/company/components/shared/management/attendance/controls/EmployeeAttendancePageControl";
export default {
  name: "MainEmployeeAttendancePageControl",
  components: { EmployeeAttendancePageControl },
  methods: {
    /**
     *
     */
    refresh() {
      const type = this.getRouteType();

      switch (type) {
        case "daily":
          this.$store.dispatch("fetchEmployeeDailyAttendance");
          break;
        case "monthly":
          this.$store.dispatch("fetchEmployeeMonthlyAttendanceList");
          break;
        case "yearly":
          this.$store.dispatch("fetchEmployeeYearlyAttendanceList");
          break;
        default:
          break;
      }
    },

    /**
     * Get route child type.
     *
     * @returns {string}
     */
    getRouteType() {
      const route = this.$route.name;
      console.log("Current route :", route);

      let type = null;
      if (route === "employeeAttendance") {
        console.log("Route is Employee daily attendance.");
        type = "daily";
      } else if (route === "employeeAttendanceMonthly") {
        console.log("Route is Employee monthly attendance.");
        type = "monthly";
      } else {
        type = "yearly";
      }

      return type;
    },
  },
};
</script>

<style scoped></style>
